import {styled} from 'styled-components';
import {default as data} from '../data/product2';
import { lazy, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

const S = {
  Product : styled.section`
    width : 100%;

    background : linear-gradient(180deg, #ffffff1a, #4788c81a);

    display : flex;

    @media(min-width : 1025px) {
      height: calc(100vh - 100px);
    }

    @media(max-width : 1024px) {
      flex-direction : column;
      min-width: 0;
      min-height: calc(100vh - 56px);
    }
    
    & ::-webkit-scrollbar {
      display: none;
    }

  `,
 
  Content : styled.section`
    background : #ffffff;
    border-radius : 100px 0 0 0;
    box-shadow: 0px 0px 30px 0px rgba(71, 136, 200, 0.15);
    flex : 1 0 0 ;


    @media(min-width : 1025px) {
      overflow-y : scroll;
      height: calc(100vh - 100px);
    }

    @media(max-width : 1024px) {
      width : 100vw;
      border-radius: 0;

      box-shadow: none;
      margin-top: 56px;
      
    }
  `,
  Divide : styled.div`
    width: 100%;
    height: 8px;
    background-color: #f3f4f6;
  `,
  Menu : styled.section`

    width : 35%;
    
    @media(max-width : 1024px) {
      width: 100%;
      position: fixed;
      top : 56px;
      left : 0;
      z-index: 4;
    }
  `
}

  const Menu = lazy(() => import('./Product/Menu'));
  const Main = lazy(() => import('./Product/Main'))
  const Feature = lazy(() => import('./Product/Feature'));
  const Spec = lazy(() => import('./Product/Spec'));

const Product = () => {
  const menulist_dict = {
    'gpu' : 'GPU System', 'hpc' : 'HPC Server', 'storage' : 'Storage', 'network' : 'Network Switch', 'nvidia' : 'NVIDIA LinkX Cables and Transceivers'
  }
  const submenulist = {
    'GPU System' : ['JN501AR', 'JN401AR / JN402AR', 'JN302AR', 'JN304AR'],
    'HPC Server' : ['JN441AR', 'JN451AR'],
    'Storage' : ['JS361AR'],
    'Network Switch' : ['NVIDIA Quantum-X800 InfiniBand Switches', 'NVIDIA Quantum-2 InfiniBand Switch Family', 'NVIDIA Quantum InfiniBand Switch Family'],
    'NVIDIA LinkX Cables and Transceivers' : ['NVIDIA 400Gb/s QSFP-DD Cables', 'NVIDIA 400Gb/s OSFP Cables', 'NVIDIA 200Gb/s QSFP56 Cables', 'NVIDIA 100Gb/s QSFP28 Cables']
  }
  
  let {state} = useLocation().state || '';
  let {idx} = useLocation().state || 0 ;
  
  if (!state) {
    state = 'GPU System'
  }
  if( idx === undefined) {
    idx = 0
  }
  
  const [select , setSelect] = useState(state); // 큰 카테고리
  const [submenu, setSubmenu] = useState(submenulist[state][idx]); // 제품 명

  useEffect(() => {
    setSelect(state);
    setSubmenu(submenulist[state][idx])

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [state, idx])

  useEffect(() => {

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [select, submenu])


  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [])


  const contentRef = useRef();

  useEffect(() => {
    contentRef.current.scrollTo({
      top : 0,
      behavior : "smooth",
    })
  }, [select, submenu])

  // useEffect(() => {
  //   contentRef.current.scrollTo({
  //     top : 0,
  //     behavior : "smooth",
  //   })
  // }, [submenu])

  

  return (
    <S.Product>
      <S.Menu>
        <Menu state={select} setMenu={(menu) => setSelect(menu)} setSubmenu={(menu) => setSubmenu(menu)}/>
      </S.Menu>
      <S.Content ref={contentRef} >
        <Main data={data[select][submenu]}/>
        {data[select][submenu]?.specs &&
          <div>
            <S.Divide/>
            <Spec data={data[select][submenu]}/>
          </div>
        }
      </S.Content>
    </S.Product>
  )
}

export default Product;