import {styled} from 'styled-components';
import { BR_M, BR_PC, Main_gif } from '../assets';
import { useEffect, lazy, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const S = {
  Home : styled.section`
    width: 100vw;
    box-sizing : border-box;

    @media(max-width : 1024px) {
      width: 100vw;
      min-width: 0;
    }
  `,
  Title : styled.h1`
    font-size : 80px;
    font-weight : 800;
    line-height : 90px;
    margin : 80px auto 0;
    color : #111111;

    @media(max-width : 1024px) {
      font-size : 35px;
      line-height : 40px;
      margin : 80px 0 40px;
    }
  `,
  GIF : styled.img`
    box-sizing : border-box;
    margin : 0 260px 147px;

    @media(max-width : 1024px) {
      width : calc(100vw - 40px);
      height : auto;
      margin : 40px 0 0; 
    }
  `,
  Banner : styled.img`
    cursor : pointer;
    width : 100vw;
  `,

}

const Tech = lazy(() => import('./Home/Tech'));
const GPU = lazy(() => import('./Home/GPU'));
const Jikji = lazy(() => import('./Home/Jikji'));
const Showroom = lazy(() => import('./Home/Showroom'));
const News = lazy(() => import('./Home/News'));

const Home = () => {

  useEffect(() => {

    if(window.innerWidth < 1024) {
      setIsmobile(true);
    }
  }, [])

  const clickbanner = () => {
    const url = 'https://event-us.kr/m/93063/23855';
    window.location.href = url;
  }

  const [ismobile, setIsmobile] = useState(false);

  return (
    <S.Home>
      
      {/* <S.Banner src={ismobile ? BR_M : BR_PC} onClick={clickbanner} alt='AI Computing Industry Week'/> */}
      <S.Title>Accelerate Your AI  Solutions</S.Title>
      <Tech/>
      <GPU/>
      {/* <Jikji/> */}
      <div id="showroom">
        <Showroom/>
      </div>
      <div id="news">
        <News/>
      </div>
    </S.Home>
  )
}

export default Home;