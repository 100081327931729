import {styled} from 'styled-components';
import { dropdown, exit_grey, logo_jikji, menu, upright, upright_g } from '../assets';
import { useLocation, useNavigate } from 'react-router-dom';
import {useEffect, useRef, useState} from 'react';
import Contact from './Contact';
import { HashLink } from 'react-router-hash-link/dist/react-router-hash-link.cjs.production';

const S = {
  Nav : styled.nav`
    width : 100vw;
    height : 100px;

    position : sticky;
    top : 0;
    left : 0;
    z-index : 2;

    display : flex;
    justify-content : space-between;
    align-items : center;
    
    box-sizing : border-box;
    padding : 0 10vw;
    background : #ffffff00;

    @media(max-width : 1024px) {
      display : none;
    }
  `,

  Logo : styled.img`
    width : 134px;
    height : 31px;
    cursor : pointer;

    &.exit {
      width : 20px;
      height : 20px;
    }

    &.grey {
      filter : grayscale(100%)
    }

    &.mobile {
      width : 80px;
      height : 20px;
    }
  `,

  Menu : styled.section`
    display : flex;
    align-items : center;
  `,
  Menu_item : styled.div`
    font-size : 20px;
    font-weight : 400;
    line-height : 20px;
    color : #8496b0;
    margin : 0 20px;

    cursor : pointer;
    text-decoration : none;

    display : flex;
    align-items : center;

    &.select {
      color : #4788C8;
      font-weight :800
    }

    & img {
      width : 10px;
      height : 10px;
      margin-left : 10px;
    }

    & a {
      text-decoration: none;
      color : #8496b0;

      &:hover {
        color : #5ea5ea;
      }
    }

    &:hover {
      color : #5ea5ea;
    }
  `,
  Menu_item_a : styled.a`
    font-size : 20px;
    font-weight : 400;
    line-height : 20px;
    color : #8496b0;
    margin : 0 20px;

    cursor : pointer;
    text-decoration : none;

    display : flex;
    align-items : center;

    &.select {
      color : 4d617c;
      font-weight :800
    }

    & img {
      width : 10px;
      height : 10px;
      margin-left : 10px;
    }

    &:hover {
      color : #5ea5ea;
    }
  `,
  Button : styled.button`
    width : 150px;
    height : 40px;
    background : #4788c8;
    color : #ffffff;
    border-radius : 50px;
    border : 0;

    font-size : 20px;
    font-weight : 400;
    line-height : 20px;

    margin-left : 20px;
    cursor : pointer;

    font-family : 'SUIT';

    &:hover {
      background-color: #5EA5EA;
    }
  `,
  M_Nav_sec : styled.section`
    width : 100vw;
    height : 100vh;
    background : #00000066;

    position : absolute;
    top : 0;
    left : 0;
    
  `,
  M_Nav : styled.nav`
    width : 100vw;
    height : 56px;

    position : fixed;
    top : 0;
    left : 0;
    z-index : 5;

    display : flex;
    justify-content : space-between;
    align-items : center;
    
    box-sizing : border-box;
    background : #ffffff;

    padding : 0 20px;

    @media(min-width : 1025px) {
      display : none;
    }
  `,
  Menu_icon : styled.img`
    width : 30px;
    height : 30px;
    cursor: pointer;

    @media(max-width : 1024px) {
      width : 20px;
      height : 20px;
    }
  `,

  Tab : styled.section`
    width : 100vw;
    background : #fff;

    position : absolute;
    top : 0;
    left : 0;

  `,
  Tab_header : styled.section`
    height : 56px;
    display : flex;
    justify-content : space-between;
    align-items : center;
    padding : 25px 20px;
    box-sizing : border-box;
  `,
  Tab_menu_section : styled.section`
    overflow-y: auto;
    max-height: calc(100vh - 80px);
  `,
  Tab_menu : styled.div`
    width : 100%;
    height : 75px;

    font-size : 25px;
    line-height : 25px;
    font-weight : 800;
    text-align : start;

    padding : 25px 30px;
    box-sizing : border-box;
    border-bottom : 2px solid #eaeaea;

    display : flex;
    justify-content : center;

    font-family : 'SUIT';
    cursor: pointer;

    &.contact {
      color : #4788c8;

    }

    & img {
      &.open {
        transform : rotate(180deg);
      }
    }

    & a {
      text-decoration: none;
      color : #000;

      &:hover {
        color : #5ea5ea;
      }
    }

    & span {
      &:hover {
        color : #5ea5ea;
      }
    }
    
  `,
  Tab_menu_a : styled.a`
    width : 100%;
    height : 75px;

    font-size : 25px;
    line-height : 25px;
    font-weight : 800;
    text-align : start;

    padding : 25px 30px;
    box-sizing : border-box;
    border-bottom : 2px solid #eaeaea;

    display : flex;
    justify-content : center;

    font-family : 'SUIT';
    text-decoration : none;
    color : #000000;

    &.contact {
      color : #4788c8;

    }

    & div {
      flex : 1 0 0;
      text-align: center;

      &:hover {
        color : #5ea5ea;
      }
    }
    & img {
      &.open {
        transform : rotate(180deg);
      }
    }
  `,
  Tab_submenu : styled.div`
    width : 100%;
    background : #ffffff;

    display : flex;
    flex-direction : column;
    justify-content : space-around;
  `,
  Tab_submenu_item : styled.div`
    width : 100%;
    height: auto;
    font-size : 1.25rem;
    color : #5f6673;
    text-align : center;
    padding : 16px;
    border-bottom : 1px solid #fff;
    background-color : #F3F4F6;

    box-sizing : border-box;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-self: stretch;
    gap: 24px;

    & div {
      text-align: center;
      flex : 1 0 0;
      cursor: pointer;
    }


    & img {
      filter: invert(97%) sepia(3%) saturate(227%) hue-rotate(183deg) brightness(95%) contrast(94%);
      transform: rotate(0);
      cursor: pointer;
    }

    &.open {
      color : #4788C8;
      font-weight: 700;

      & img {
        transform: rotate(180deg);
        filter: invert(44%) sepia(44%) saturate(603%) hue-rotate(169deg) brightness(104%) contrast(97%);
      }
    }

    &:hover {
      color : #5ea5ea;
    }
  `,
  Subsubmenu_section : styled.section`
    padding : 25px 16px;
    background-color: #F3F4F6;
    width: 100%;
    box-sizing: border-box;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap : 24px;
    
  `,
  Subsubmenu : styled.div`
    padding : 8px 16px;
    color : #5F6673;
    font-size: 1rem;
    font-weight : 400;
    line-height: 24px;
    width: fit-content;
    cursor: pointer;

    &.select {
      background-color: #4788C8;
      border-radius: 40px;
      color : #fff;
      font-weight: 700;

      &:hover {
        background-color: #5ea5ea;
        color : #fff;
      }
    }

    &:hover {
      color : #5ea5ea;
    }
  `
}
const Nav = () => {

  const navigate = useNavigate();
  const [isopen, setIsopen] = useState(false); //contact modal
  const [istabopen, setIstabopen] = useState(false) // mobile menutab
  const [issubopen, setIssubopen] = useState(false) // mobile menutab
  const [issubsubopen, setIssubsubopen] = useState('');
  
  const closeModal = () => {setIsopen(false)};

  const location = useLocation();
  const {pathname} = useLocation();
  let {state} = useLocation()?.state || '';
  let {idx} = useLocation()?.state || '';
  if (!state && pathname === '/product' ) { state = 'GPU System'}
  else if (pathname === '/') {state = ''}
  
  if (idx === undefined) {idx = 0}

  const [url, setUrl] = useState('/')

  const menulist = ['GPU System', 'HPC Server', 'Storage', 'Network Switch', 'NVIDIA LinkX Cables and Transceivers']
  const submenulist = {
    'GPU System' : ['JN501AR', 'JN401AR / JN402AR', 'JN302AR', 'JN304AR'],
    'HPC Server' : ['JN441AR', 'JN451AR'],
    'Storage' : ['JS361AR'],
    'Network Switch' : ['NVIDIA Quantum-X800 InfiniBand Switches', 'NVIDIA Quantum-2 InfiniBand Switch Family', 'NVIDIA Quantum InfiniBand Switch Family'],
    'NVIDIA LinkX Cables and Transceivers' : ['NVIDIA 400Gb/s QSFP-DD Cables', 'NVIDIA 400Gb/s OSFP Cables', 'NVIDIA 200Gb/s QSFP56 Cables', 'NVIDIA 100Gb/s QSFP28 Cables']
  }

  useEffect(() => {
    setUrl(location.pathname)
  }, [location])
  
  useEffect(() => {
    if (isopen || istabopen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isopen, istabopen])

  const [scroll, setScroll] = useState(0)
  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.scrollY);
    };

    // 스크롤 이벤트 리스너 등록
    window.addEventListener('scroll', handleScroll);

    // 클린업 함수
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const setbg = () => {
    if (location.pathname === '/tech') {
      let background = '#ffffff'
      const height = window.innerHeight;
      
      if (scroll / height < 1) {
        background = '#4788c8';
      } else if (scroll / height >= 2 && scroll / height < 3) {
        background = '#42bfda'
      } else {
        background = '#ffffff'
      }

      return {
        background
      }
    } 
  }

  const setwhite = () => {
    if(location.pathname === '/tech') {
      const height = window.innerHeight;
      if (scroll / height < 1 || (scroll / height >= 2 && scroll / height < 3)) {
        return {
          filter : 'brightness(0) invert(1)',
          color : '#ffffff'
        }
      } 
    }
  }
  
  const setreverse = () => {
    if(location.pathname === '/tech') {
      const height = window.innerHeight;
      if (scroll / height < 1) {
        return {
          background : '#ffffff',
          color : '#4788c8'
        }
      } else if(scroll / height >= 2 && scroll / height < 3) {
        return {
          background : '#ffffff',
          color : '#42bfda'
        }
      }
    }
  }


  const tabRef = useRef();
  const checkclick = (e) => {
    if (tabRef?.current && !tabRef?.current.contains(e.target)) {
      setIstabopen(!istabopen)
    }
  }

  return (
    <>
      <S.Nav style={setbg()}>
        <S.Logo style={setwhite()} src={logo_jikji} onClick={() => navigate('/', {state: 'main'})} alt='logo'/>

        <S.Menu>
          <S.Menu_item style={setwhite()} onClick={() => navigate('/tech')} className={url === '/tech' ? 'select' : ''}>Technology</S.Menu_item>
          <S.Menu_item style={setwhite()} onClick={() => navigate('/product')} className={url === '/product' ? 'select' : ''}>Products</S.Menu_item>
          <S.Menu_item style={setwhite()} className={url === '' && 'select'}><HashLink smooth to="/#showroom">Showroom</HashLink></S.Menu_item>
          <S.Menu_item style={setwhite()} className={url === '' && 'select'}><HashLink smooth to="/#news">News</HashLink></S.Menu_item>
          <S.Menu_item_a style={setwhite()} href="https://n3n.ai" target='_blank'>N3N
            <img src={upright} alt="arrow"/> 
          </S.Menu_item_a>
          <S.Button style={setreverse()} onClick={() => setIsopen(!isopen)}>Contact Us</S.Button>
        </S.Menu>

      </S.Nav>
      {isopen && <Contact closeModal={closeModal}/>}




      <S.M_Nav>
        <span/>
        <S.Logo className='mobile' src={logo_jikji} onClick={() => {navigate('/', {state : 'main'}); setIssubsubopen(''); setIssubopen(false)}} alt='jikji logo'/>
        <S.Menu_icon src={menu} onClick={() => {setIstabopen(!istabopen);}} alt='menu icon'/>

        {istabopen &&
          <S.M_Nav_sec onClick={(e) => checkclick(e)}>
            <S.Tab ref={tabRef}>
              <S.Tab_header>
                <span/>
                <S.Logo src={logo_jikji} className='mobile' onClick={() => navigate('/')} alt='jikji logo'/>
                <S.Logo src={exit_grey} className="exit" onClick={() => {setIstabopen(false); setIssubopen(false)}} alt='exit'/>
              </S.Tab_header>

              <S.Tab_menu_section>
                <S.Tab_menu onClick={() => {navigate('/tech'); setIstabopen(false)}}><span>Technology</span></S.Tab_menu>
                <S.Tab_menu onClick={() => setIssubopen(!issubopen)}><span>Products</span></S.Tab_menu>
                {issubopen &&
                  <S.Tab_submenu>
                    {menulist.map((item, key) => (
                      <div>
                        <S.Tab_submenu_item className={issubsubopen === item ? 'open' : ''}>
                          <div onClick={() => {navigate('/product', {state : {state : item}}); setIstabopen(false); setIssubopen(false); setIssubsubopen(item) }}>{item}</div>
                          <img src={dropdown} onClick={() => {setIssubsubopen(item)}} alt="dropdown" className={!issubopen && 'open'} style={{'width' : '15px' }}/>
                        </S.Tab_submenu_item>
                        {issubsubopen === item &&
                          <S.Subsubmenu_section>
                            {submenulist[item].map((subitem, subkey) => (
                              <S.Subsubmenu onClick={() => {navigate('/product', {state : {state : item, idx : subkey}}); setIstabopen(false)}} className={state === item && idx===subkey ? 'select' : ''}>
                                {subitem}
                              </S.Subsubmenu>
                            ))}
                          </S.Subsubmenu_section>
                        }               
                      </div>
                    ))}
                  </S.Tab_submenu>
                }
                <S.Tab_menu onClick={() => {setIstabopen(false)}}><HashLink smooth to="/#showroom">Showroom</HashLink></S.Tab_menu>
                <S.Tab_menu onClick={() => {setIstabopen(false)}}><HashLink smooth to="/#news">News</HashLink></S.Tab_menu>
                <S.Tab_menu_a href="https://n3n.ai" target='_blank'><div>N3N</div><img src={upright_g} alt="arrow" style={{'width' : '15px'}}/></S.Tab_menu_a>
                <S.Tab_menu className='contact' onClick={() => {setIsopen(!isopen); setIstabopen(false)}}><span>Contact Us</span></S.Tab_menu>
              </S.Tab_menu_section>
            </S.Tab>
          </S.M_Nav_sec>
        }
      </S.M_Nav>
    </>
  )
}

export default Nav;