import { amd_icon, ddr5_icon, gpu1_main, gpu1_spec, gpu2_main, gpu2_spec, gpu3_main, gpu3_spec, gpu4_main, gpu4_spec, hpc1_main, hpc1_spec, hpc2_main, hpc2_spec, intel_icon, network1, network2, network3, nvidia1, nvidia2, nvidia2_icon, nvidia3, nvidia4, nvidia_icon, pci_icon, storage_main, storage_spec } from "../assets";

const product = {
  "GPU System" : {
    'JN501AR' : {
      name : 'JN501AR',
      image : gpu1_main,
      description : 'Most Advanced Platform for AI and HPC Workloads',
      feature : [
        "Modular architecture provides customers with the highest performance platform",
        "Best GPU-GPU communication with Nvidia NVlink and NVswitch Architecture on H100/H800/B100 8 GPU HGX architecture",
        "Latest DDR5 and PCIe gen 5.0 ready platform design",
        "Optimized switchboard provides maximum bandwidth for GPU direct RDMA",
        "Increase power efficiency and reliability by decoupling 12V and 54V power source",
        "Support liquid cooling system (optional)"
      ],
      icons : [nvidia_icon, ddr5_icon, intel_icon, pci_icon, amd_icon],
      spec_image : gpu1_spec,
      specs : [
        {'Size' : ['8U Rackmount, W x H x D: 448x 352x 850 mm (17.63x 13.85x 33.46inch)']},
        {'GPU module' : [
          'Option(1) 8x NVIDIA® HGX™ H100/H200',
          'Option(2) 8x NVIDIA® HGX™B200 (LC)'
        ]},
        {'Motherboard' : ['4th and 5th Gen Intel Xeon Scalable Processor up to 385W']},
        {'PCIe Slot (from MLB)' : [
          'Option(1) 1x FHHLDW',
          'Option(2) 2x FHHLSW',
          'Option(3) 1x OCP 3.0 SFF + 1x FHHLSW'
        ]},
        {'PCIe Slot (from Switch)' : ['8x Low Profile card (PCIeG5x16)']},
        {'Storage (from MLB)' : [
          '4x U.2 NVMe SSD bays from CPU',
          '2x M.2 SATA SSD or NVMe SSD'
        ]},
        {'Management Port' : ['1x RJ45 for BMC(AST2600) remote management']},
        {'Cooling' : [
          'Option(1) Air cooling',
          'Option(2) Liquid cooling'
        ]},
        {'Fan' : [
          '10x 8086 Fan for GPU cooling',
          '5x 8056 Fan for CPU cooling'
        ]},
        {'PSU' : [
          '3300W 54V PSU, support 3+3 redundancy',
          '2400W 12V PSU, support 1+1 redundancy'
        ]},
        {'SW' : ['UEFI BIOS (Optional OS, SW Stack)']}
      ]
    },
    'JN401AR / JN402AR' : {
      name : 'JN401AR / JN402AR',
      image : gpu2_main,
      description : 'Powerful 4U GPU AI accelerator system',
      feature : [
        "Modular architecture provides customers with the highest performance platform",
        "Supports 4th and 5th generation Intel Xeon Scalable processors up to 385W",
        "Supports up to 10 GPU cards - CPU supports DDR5 and PCIe Gen5 supported platforms",
        "Best GPU-GPU communication with NVIDIA® NVLink® bridge",
        "Inventec-Designed Switchboard - Delivering Maximum Bandwidth for GPU Direct RDMA",
        "3200W/ 2700W/ 2400W CRPS 2+2 / 3+1 Redundancy",
        "Provides powerful thermal solution, CPU up to 400W, GPU up to 450W"
      ],
      icons : [nvidia_icon, ddr5_icon, intel_icon, pci_icon, amd_icon],
      spec_image : gpu2_spec,
      specs : [
        {'Form Factor' : ['4U Rackmount W x H x D: 447x 175x 800 mm (17.59x 6.8x 31.4 inch)']},
        {'GPU module' : [
          'Active or passive GPUs, Up to 450W',
          'Option(1) 8x NVIDIA® HGX™ H100 PCIe',
          'Option(2) 8 x NVIDIA® L40S PCIe'
        ]},
        {'Motherboard' : ['4th and 5th Gen Intel Xeon Scalable Processor up to 385W']},
        {'Front /Rear I/O' : [
          'UID button with blue led',
          'PWR button with red/gen led',
          'System healthy Bi-led (Red & Green)',
          'USB3.0 • Video connector (VGA)',
          'Dedicated Gigabit MGMT with RJ45 Port',
          'Type C(uart,I3C)'
        ]},
        {'Front/Rear Device' : [
          {'Front' : ['Up to 24SFF or 12LFF']},
          {'Rear' : [
            '1. Option 1: 1x OCP 3.0',
            'Option 2: 1xFHHLSW',
            '2. Option 1: 10x FHFLDW GPU+1xFHHLSW',
            'Option 2: 8x FHFLDW GPU+5xFHHLSW'
          ]}
        ]},
        {'Fan' : ['Up to 10x8056']},
        {'PSU' : ['3200W/ 2700W/ 2400W CRPS 2+2 / 3+1 Redundancy']},
        {'SW' : ['UEFI BIOS(optional OS, SW Stack)']}
      ]
    },
    'JN302AR' : {
      name : 'JN302AR',
      image : gpu3_main,
      subtitle : '4th and 5th Generation Intel Xeon Scalable Processors',
      description : 'Highly scalable AI server system',
      feature : [
        "Dual 4th Generation Intel® Xeon® Scalable Processors",
        "Extremely flexible performance for a variety of modern workloads",
        "Excellent expandability with up to PCIe Gen5 x16 DW FHFL GPU card x4",
        "liquid cooling options",
        "Efficient management system remotely controlled and monitored"
      ],
      icons : [nvidia_icon, ddr5_icon, intel_icon, pci_icon, amd_icon],
      spec_image : gpu3_spec,
      specs : [
        {'Form Factor' : ['2U 19” Rack Mount W x H x D: 438/447x 87x 850 mm (17.2/17.5x 3.42x 33.4 inch)']},
        {'Processor' : ['Intel Xeon Sapphire Rapids and Emerald Rapids processor']},
        {'GPU module' : [
          'Option(1) 4x NVIDIA® HGX™ H100 (PCIe)',
          'Option(2) 4x NVIDIA® HGX™ 4090 (PCIe)'
        ]},
        {'Memory Slot' : [
          {'' : ['32 xDDR5 DIMM', 'Support DDR5 RDIMM/3DS DIMM', '32GB/64GB/96GB/128GB']},
          {'Support Sapphire Rapids' : ['4800 MT/s @ 1DPC, 4400 MT/s @ 2DPC']},
          {'Emerald Rapids' : ['5600 MT/s @ 1DPC, 4800 MT/s @ 2DPC']}
        ]},
        {'PCIe' : [
          '80 PCIe lanes/Per CPU',
          'Total 160 PCIe lane'
        ]},
        {'Storage' : [
          {'Front IO configuration' : [
            'Up to 14x NVMe SSD, U.2 or E3.S form factor (40w max),',
            'support 2x NVMe/E3.S for each BP Up to 28x E1.S SSD',
            '(PCIe Gen5 X2) , support 4x E1.S for each BP',
            'Up to 12x 3.5” HDD'
          ]},
          {'Inside' : ['Support PCIe/SATA M.2 (2280, 22110)']},
          {'Rear IO configuration' : [
            'Up to 24x NVMe SSD U.2 (support PCIe Gen5)',
            'Up to 12x 3.5” HDD'
          ]}
        ]},
        {'BMC' : ['AST2600 chipset with 1GB RAM']},
        {'Security' : ['TPM2.0']},
        {'PowerSupply' : [
          'CRPS power supply support 100V-240V,',
          '240V HVDC support 1+1 active-active support hot plug'
        ]},
        {'Fan' : ['N+1 redundancy, 6x 6056']},
        {'Network Controller' : ['Support OCP 3.0 and Smart NIC card']},
        {'SW' : ['UEFI BIOS(optional OS, SW Stack)']}
      ]
    },
    'JN304AR' : {
      name : 'JN304AR',
      image : gpu4_main,
      subtitle : '2U2P 4th Generation AMD EPYC™ processor-based server',
      description : 'Highly scalable AI server system',
      feature : [
        "4th Generation AMD EPYC™ Processor",
        "AMD Infinity Guard support",
        "Extremely flexible performance for a variety of modern workloads",
        "Excellent expandability with up to PCIe Gen5 x16 DW FHFL GPU card x4",
        "liquid cooling options",
        "Efficient management system remotely controlled and monitored"
      ],
      icons : [nvidia_icon, ddr5_icon, intel_icon, pci_icon, amd_icon],
      spec_image : gpu4_spec,
      specs : [
        {'Form Factor' : ['2U2P W x H x D: 438/447x 87x 850 mm (17.2/17.5x 3.42x 33.4 inch)']},
        {'Processor' : ['Support Dual AMD Genoa SP5 Processor, support maximum TDP 500W CPU cooling solution']},
        {'GPU module' : [
          'Option(1) 4x NVIDIA® HGX™ H100 (PCIe)',
          'Option(2) 4x NVIDIA® HGX™ 4090 (PCIe)'
        ]},
        {'Memory' : [
          '24 x DDR5 DIMMs 24 DDR5 DIMM Support DDR5',
          'RDIMM/3DS DIMM 32GB/64GB/96GB/128GB/258GB',
          'Support 4800 @ 1DPC'
        ]},
        {'Storage' : [
          {'Front IO configuration' : [
            'Up to 14x NVMe SSD, U.2 or E3.S form factor (40w max),',
            'support 2x NVMe/E3.S for each BP Up to 28x E1.S SSD',
            '(PCIe Gen5 X2) , support 4x E1.S for each BP',
            'Up to 12x 3.5” HDD'
          ]},
          {'Inside' : ['Support PCIe/SATA M.2 (2280, 22110)']},
          {'Rear IO configuration' : [
            'Up to 24x NVMe SSD U.2 (support PCIe Gen5)',
            'Up to 12x 3.5” HDD'
          ]}
        ]},
        {'Network Controller' : ['Support OCP 3.0 and Smart NIC card']},
        {'TPM' : ['AST2600 chipest with 1GB RAM']},
        {'PowerSupply' : [
          'CRPS power supply support 100V-240V,',
          '240V HVDC support 1+1 active-active support hot plug'
        ]},
        {'Fan' : ['6 x 6056 Fan(dual motors)']},
        {'SW' : ['UEFI BIOS(optional OS, SW Stack)']}
      ]
    },
  },


  "HPC Server" : {
    'JN441AR' : {
      name : 'JN441AR',
      image : hpc1_main,
      subtitle : '2U2N8P 4th Generation AMD EPYC 9004™ processor-based server',
      description : 'Computing server supporting various processors',
      feature : [
        "Contains 4 nodes of 2 processors",
        "The best virtualization solution with optimal total cost of ownership (TCO) and enhanced security",
        "Infinite scalability implemented with the latest technologies such as DDR5, PCIe Gen5, and OCP 3.0",
        "Modular design provides excellent maintainability and flexibility"
      ],
      icons : [],
      spec_image : hpc1_spec,
      specs : [
        {'Form Factor' : ['W x H x D: 448x 875x 916 mm (17.63x 3.44x 38.43 inch)']},
        {'Processor' : [
          'AMD SP5 - Genoa (2 CPUs)',
          'AMD SP5 Socket (SMLGA 6096)',
          'Up to 400W TDP',
          'xGMI-4 up to 32 Gbps'
        ]},
        {'Memory' : [
            '12 channels DDR5 per CPU @ 5200MTS (1DPC)',
            '(Total: 24 DIMMs)'
        ]},
        {'PCIe' : ['64(w/4-link XGMI) lanes PCIe5, plus 6 bonus lanesPCIe3 (per CPU)']},
        {'Chipset' : ['Chipset BMC: Aspeed AST2600 (AST2600A1-GP)']},
        {'Storage Controller' : [
          'Support up to 2 x U.2 NVMe SSDs per node',
          'Supports M.2 22110',
          'Front (2U 4Node)',
          '• Support 2 x 4SFF U.2 NVMe HDD Bays'
        ]},
        {'Network Controller' : ['Intel I210 1Gb single port, x16 OCP 3.0 Connector']},
        {'TPM' : ['Embedded TPM2.0']},
        {'PowerSupply' : ['Four 2400W PSUs/3+1']},
        {'SW' : ['UEFI BIOS(optional OS, SW Stack)']}
      ]
    },
    'JN451AR' : {
      name : 'JN451AR',
      image : hpc2_main,
      subtitle : '2U2N8P 4th generation Intel Xeon Scalable processor',
      description : 'Computing server supporting various processors',
      feature : [
        "Contains 4 nodes of 2 processors",
        "The best virtualization solution with optimal total cost of ownership (TCO) and enhanced security",
        "Infinite scalability implemented with the latest technologies such as DDR5, PCIe Gen5, and OCP 3.0",
        "Modular design provides excellent maintainability and flexibility"
      ],
      icons : [],
      spec_image : hpc2_spec,
      specs : [
        {'Form Factor' : ['W x H x D: 448x 875x 916 mm (17.63x 3.44x 38.43 inch)']},
        {'Processor' : [
          'Sapphire Rapids + HBM (optional) (2 Processors)',
          'Intel Socket (LGA4677)',
          'Up to 350W TDP',
          'UPI up to 16GT/s'
        ]},
        {'Memory' : [
            '8 channels DDR5 per CPU @ 4800MTS (1DPC)',
            '(Total : 16 DIMMs)'
        ]},
        {'PCIe' : ['PCIe Gen5 80 lanes']},
        {'Chipset' : ['PCH: Intel Emmitsburg, BMC: Aspeed AST2600']},
        {'Storage Controller' : [
          'Support up to 2 x U.2 NVMe SSDs per node',
          'Supports M.2 22110',
          'Front (2U 4Node)',
          '• Support 2 x 4SFF U.2 NVMe HDD Bays'
        ]},
        {'Network Controller' : ['Intel I210 1Gb single port, OCP 3.0 Connector']},
        {'TPM' : ['Embedded TPM2.0']},
        {'PowerSupply' : ['Four 2400W PSUs/3+1']},
        {'SW' : ['UEFI BIOS(optional OS, SW Stack)']}
      ]
    },
  },


  "Storage" : {
    'JS361AR' : {
      name : 'JS361AR',
      image : storage_main,
      description : '2U42 bay powerful storage system',
      feature : [
        "Provides 42 SATA drives (3.5\") in a 2U form factor",
        "Efficient management system providing remote monitoring capabilities",
        "Easy serviceability with front-serviceable hard drives and hot-pluggable fans",
        "Wide range application support"
      ],
      icons : [],
      spec_image : storage_spec,
      specs : [
        {'Form Factor' : ['2U D(925 mm), W(447mm), H(86.8mm)']},
        {'Storage' : ['42x 3.5” SATA HDD bays, Side-Loaded']},
        {'Management NIC' : ['1G Based-T from AST2520 BMC']},
        {'Front I/O & Rear I/O' : [
          'Mini SAS HD x4 *2',
          'SAS Cable LED *2',
          'HDD Fail LED*2',
          'RJ45 with LED (Management NIC)',
          'RJ45 W/O LED (UART)',
          'Mini USB(I2C)',
          'UID Button&LED (with Reset function)',
          'Power Button&LED',
          'Health LE'
        ]},
        {'Fan' : ['4 x 8056 hot-swappable dual-rotor fans with N+1 redundancy']},
        {'PowerSupply' : ['2 x 800W Power Supplies with 1+1 redundancy']},
        {'Temperature' : ['5°C - 35°C']}
      ]
    },
  },



  "Network Switch" : {
    'NVIDIA Quantum-X800 InfiniBand Switches' : {
      name : 'NVIDIA Quantum-X800 InfiniBand Switches',
      image : network1,
      description : 'World’s First End-to-End 800Gb/s Networking',
      feature : [
        "The NVIDIA Quantum-X800 InfiniBand switch supports 800 gigabits per second (Gb/s) of throughput and delivers advanced NVIDIA in-network computing features with ultra-low latency. This helps improve overall application performance within high-performance computing (HPC) and artificial intelligence (AI) data centers.",
      ],
      icons : [nvidia2_icon],
    },
    'NVIDIA Quantum-2 InfiniBand Switch Family' : {
      name : 'NVIDIA Quantum-2 InfiniBand Switch Family',
      image : network2,
      description : 'World’s First End-to-End 800Gb/s Networking',
      feature : [
        "NVIDIA's Quantum-2 InfiniBand switches deliver 400 Gb/s of throughput and deliver cost-effective performance in high-performance computing (HPC), artificial intelligence (AI), and hyperscale cloud infrastructure with in-network compute, smart acceleration engines, flexibility, and powerful architecture. Helps you achieve superior performance while reducing complexity and complexity."
      ],
      icons : [nvidia2_icon],
    },
    'NVIDIA Quantum InfiniBand Switch Family' : {
      name : 'NVIDIA Quantum InfiniBand Switch Family',
      image : network3,
      description : 'World’s First End-to-End 800Gb/s Networking',
      feature : [
        "NVIDIA Quantum InfiniBand switches deliver high-bandwidth performance, low power consumption, and outstanding scalability to reduce capital and operational costs and deliver the best return on investment. Additionally, NVIDIA Quantum Switches optimize connectivity between data centers with advanced routing and congestion avoidance features."
      ],
      icons : [nvidia2_icon],
    },
  },



  "NVIDIA LinkX Cables and Transceivers" : {
    'NVIDIA 400Gb/s QSFP-DD Cables' : {
      name : 'NVIDIA 400Gb/s QSFP-DD Cables',
      image : nvidia1,
      description : 'Infiniband and Ethernet for 100G/200G/400G and 800G infrastructure',
      feature : [
        "400Gb/s cables and transceivers are used to connect Spectrum-4 SN5400 and Spectrum-3 SN4000 series Ethernet switches with ConnectX-6/7 network adapters and BlueField-2/3 DPUs. The product line consists of DAC cables up to 2.5m, active fiber from 3m to 100m, multimode optics up to 100m, and single-mode optics up to 500m, 2km and 10km. The switches use Quad Small Form Factor Double Density (QSFP-DD) connectors, while ConnectX-6 and BlueField-2 use QSFP56 and QSFP28 connectors."
      ],
    },
    'NVIDIA 400Gb/s OSFP Cables' : {
      name : 'NVIDIA 400Gb/s OSFP Cables',
      image : nvidia2,
      description : 'Infiniband and Ethernet for 100G/200G/400G and 800G infrastructure',
      feature : [
        "N800Gb/s and 400Gb/s cables and transceivers support Quantum-2 InfiniBand and Spectrum-4 Used to connect SN5600 Ethernet switches with ConnectX-7 network adapters, BlueField-3 DPUs, and NVIDIA DGX™ H100. GPU system. The product line extends up to 3m(m). It consists of direct attach cable (DAC), 3m to 5m of linear active copper cable, 50m of multimode optics, and 100m, 500m, and 2 kilometers (km) of single-mode optics. The switch uses only twin-port octal small form factor pluggable (OSFP) connectors. ConnectX-7 supports OSFP and QSFP112 All are used, and BlueField-3 only uses QSFP112."
      ],
    },
    'NVIDIA 200Gb/s QSFP56 Cables' : {
      name : 'NVIDIA 200Gb/s QSFP56 Cables',
      image : nvidia3,
      description : 'Infiniband and Ethernet for 100G/200G/400G and 800G infrastructure',
      feature : [
        "200Gb/s cables and transceivers are used to connect Quantum InfiniBand and 200GbE Spectrum-2/3/4 Ethernet switches with ConnectX-6/7 network adapters, BlueField-2/3 DPU, and DGX A100 GPU systems. The product line consists of DAC cables up to 2.5m, active fiber cables from 3m to 100m, multimode optical cables up to 100m, and single-mode optical cables up to 2km. The switches, ConnectX-6/7 and BlueField-2/3 use QSFP56 connectors."
      ],
    },
    'NVIDIA 100Gb/s QSFP28 Cables' : {
      name : 'NVIDIA 100Gb/s QSFP28 Cables',
      image : nvidia4,
      description : 'Infiniband and Ethernet for 100G/200G/400G and 800G infrastructure',
      feature : [
        "25 Gb/s and 100 Gb/s cables and transceivers support Enhanced Data Rate (EDR) InfiniBand and 100GbE Spectrum-1/2/3/4 Ethernet switch with ConnectX-6/7 network adapter used to connect with BlueField-2/3 DPU. The product line consists of DAC cables up to 5m, active optical cables up to 100m, multimode optical and single-mode optical cables up to 500m, 2km, 10km and 40km. Switches, ConnectX-6/7 and BlueField-2/3 use QSFP28 and SFP28 connectors."
      ],
    },
  }
  
}

export default product;