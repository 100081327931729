import {styled} from 'styled-components'; 

const S = {
  Policy : styled.section`
    width : 100%;
    height : 100%;
    padding : 40px;
    box-sizing : border-box;
    display : flex;
    flex-direction : column;

    @media(max-width : 1024px) {
      padding : 20px;
    }
  `,
  Title : styled.h3`
    font-size : 40px;
    font-weight : 800;
    line-height : 40px;
    color : #000000;
    text-align : start;
    margin : 14px 0 14px 10px;
  `,
  Subtitle : styled.div`
    font-size : 24px;
    font-weight : 500;
    line-height : 30px;
    color : #999999;
    text-align : start;
    margin : 0 10px 40px;
  `,
  Content : styled.div`
    width : 100%;
    background : #f0f0f0;
    border-radius : 10px;
    flex-grow : 1;
    text-align : start;
    overflow-y : auto;
    padding : 35px 20px;
    box-sizing : border-box;
    font-family : 'SUIT';

    & iframe {
      border : 0;
    }

    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  `
}
const Policy = () => {

  const iframePracyPart = () => {
    return {
      __html : '<iframe src="./Privacy_Policy.html" width="100%" height="100%"></iframe>'
    }
  }
  return (
    <S.Policy>
      <S.Title>PRIVACY POLICY</S.Title>
      <S.Subtitle>Last Updated May 28, 2024</S.Subtitle>

      <S.Content dangerouslySetInnerHTML={iframePracyPart()}>
      </S.Content>
    </S.Policy>
  )
}
export default Policy;