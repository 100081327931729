import { useEffect } from 'react';
import {styled} from 'styled-components';
import {cloud, datacenter, manufacturing, server, server2_m, datacenter2_m, manufacturing2_m, cloud2_m} from '../assets';

const S = {
  Tech : styled.div`
    width : 100vw;
    overflow-y : auto;
    // postition : relative;
  
  `,
  Server : styled.section`
    width : 100vw;

    background : #4788c8;

    display : flex;
    justify-content : center;
    align-items : center;

    padding : 80px 0;
    box-sizing : border-box;

    @media(min-width : 1025px) {
      height: calc(100vh - 100px);
    }

    @media(max-width : 1024px) {
      height : auto;
      padding : 40px 0;
    }
  `,
  DataCenter: styled.section`
    width : 100vw;
    height : 100vh;

    display : flex;
    justify-content : center;
    align-items : center;
    padding : 80px 0;
    box-sizing : border-box;

    @media(max-width : 1024px) {
      height : auto;
      padding : 40px 0;
    }
  `,
  Manufacuring : styled.section`
    width : 100vw;
    height : 100vh;
    background : #42bfda;

    display : flex;
    justify-content : center;
    align-items : center;
    padding : 80px 0;
    box-sizing : border-box;

    @media(max-width : 1024px) {
      height : auto;
      padding : 40px 0;
    }
  `,
  Cloud : styled.section`
    width : 100vw;
    height : 100vh;
    display : flex;
    justify-content : center;
    align-items : center;
    padding : 80px 0;
    box-sizing : border-box;

    @media(max-width : 1024px) {
      height : auto;
      padding : 40px 0;
    }
  `,
  Img : styled.img`
    width : 80%;

    &.web {
      @media(max-width : 1024px) {
        display : none;
      }
      @media(min-width : 1025px) {
        display : block;
      }
    }
    &.mobile {
      width : 100%;
      object-fit : contain;
      @media(max-width : 1024px) {
        display : block;
      }
      @media(min-width : 1025px) {
        display : none;
      }
    }
  `
}

const Tech = () => {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [])

  useEffect(() => {
    const wheelHandler = (e) => {
      const {deltaY} = e;
      const {scrollY} = window;
      let pageHeight = 0;
      
      if (window.innerWidth <= 1024) {
        pageHeight = window.innerHeight - 80;
      } else {
        pageHeight = window.innerHeight;
      }

      if (deltaY > 0 ) {
        window.scrollTo({
          top: pageHeight * (Math.floor(scrollY / pageHeight) + 1),
          behavior: "smooth",
        });
      } else {
        window.scrollTo({
          top: pageHeight * (Math.floor(scrollY / pageHeight)),
          behavior: "smooth",
        });
      }

    }

    window.addEventListener("wheel", wheelHandler);
    return () => {
      window.removeEventListener("wheel", wheelHandler);
    }
  }, [])

  return (
    <S.Tech>
      <S.Server>
        <S.Img src={server} className='web' alt='server'/>
        <S.Img src={server2_m} className='mobile' alt='server'/>
      </S.Server>
      <S.DataCenter>
        <S.Img src={datacenter} className='web' alt='datacenter'/>
        <S.Img src={datacenter2_m} className='mobile' alt='datacenter'/>
      </S.DataCenter>
      <S.Manufacuring>
        <S.Img src={manufacturing} className='web' alt='manufacuring'/>
        <S.Img src={manufacturing2_m} className='mobile' alt='manufacuring'/>
      </S.Manufacuring>
      <S.Cloud>
        <S.Img src={cloud} className='web' alt='cloud'/>
        <S.Img src={cloud2_m} className='mobile' alt='cloud'/>
      </S.Cloud>
    </S.Tech>
  )
}

export default Tech;